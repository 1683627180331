import { styled } from 'styled-components';
import { Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, Suspense, useCallback, useEffect } from 'react';
import { Axios, Sentry } from '@idk-web/core-utils';
import {
  useNotification,
  ConfirmDialog,
  useDialog,
  useVisibility,
  useSuspended,
  Box,
  ErrorBoundary,
  ErrorDialog,
} from '@idk-web/core-ui';
import { resendOwnPortalUserValidationEmail } from '@idk-web/api';
import { translateError } from '@/utils/error';
import { PortalTab } from '@/types/idkollen';
import { SentryRoute } from '@/sentry';
import { selectPortalLoginStatus } from '@/redux/portalAuth.slice';
import { usePortalUser } from '@/hooks/portal/usePortalUser';
import { useCompany } from '@/hooks/portal/useCompany';
import { usePortalLogin } from '@/hooks/portal/login/usePortalLogin';
import { DEFAULT_NOTIFICATION_AUTO_HIDE_MS } from '@/config';
import routes from '@/components/portal/routes';
import ProfileScreen from '@/components/portal/profile/ProfileScreen';
import UnauthenticatedRoute from '@/components/portal/UnauthenticatedRoute';
import Layout from '@/components/portal/Layout';
import ChangePassword from '@/components/portal/ChangePassword';
import AuthenticatedRoute from '@/components/portal/AuthenticatedRoute';
import ScheduledMaintenanceMessage from '@/components/common/ScheduledMaintenanceMessage';
import BrowserSupportMessage from '@/components/common/BrowserSupportMessage';

const ErrorScreen = React.lazy(() => import('@/components/error/ErrorScreen'));
const LoginCallback = React.lazy(
  () => import('@/components/common/auth/LoginCallback'),
);
const EmailValidationScreen = React.lazy(
  () => import('@/components/portal/email/EmailValidationScreen'),
);
const EmailRequestScreen = React.lazy(
  () => import('@/components/portal/email/EmailRequestScreen'),
);
const ChangePasswordScreen = React.lazy(
  () => import('@/components/portal/login/ChangePasswordScreen'),
);
const LoginScreen = React.lazy(
  () => import('@/components/portal/login/LoginScreen'),
);
const SsoCallback = React.lazy(
  () => import('@/components/portal/sso/SsoCallback'),
);
const LoginSsoScreen = React.lazy(
  () => import('@/components/portal/sso/LoginSsoScreen'),
);
const SessionExpiredScreen = React.lazy(
  () => import('@/components/portal/sso/SessionExpiredScreen'),
);
const TeleIdScreen = React.lazy(
  () => import('@/components/portal/teleid/TeleIdScreen'),
);
const TeleSignScreen = React.lazy(
  () => import('@/components/portal/telesign/TeleSignScreen'),
);
const SafeMailScreen = React.lazy(
  () => import('@/components/portal/safemail/SafeMailScreen'),
);
const AdminScreen = React.lazy(
  () => import('@/components/portal/admin/AdminScreen'),
);
const NotFoundScreen = React.lazy(
  () => import('@/components/notFound/NotFoundScreen'),
);

const Container = styled(Box).attrs({
  direction: 'vertical',
})``;

const PortalApp: FC = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const notification = useNotification();
  const auth = usePortalLogin();
  const company = useCompany();
  const user = usePortalUser();
  const loginStatus = useSelector(selectPortalLoginStatus);
  const visibility = useVisibility();
  const reloadCompany = useCallback(async () => {
    if (loginStatus !== 'AUTHENTICATED' || visibility !== 'visible') {
      return;
    }

    try {
      await company.reload();
      await user.reload();
    } catch (e) {
      if (Axios.isError(e) && e.response && Axios.is4xxResponse(e.response)) {
        await auth.logout();
      } else {
        console.error('Failed to reload company', e);
      }
    }
  }, [loginStatus, visibility]);

  useSuspended(reloadCompany, [reloadCompany]);

  useEffect(() => {
    void reloadCompany();
  }, [reloadCompany]);

  useEffect(() => {
    if (
      loginStatus === 'AUTHENTICATED' &&
      user?.email &&
      !user?.hasValidatedEmail
    ) {
      const onOk = async () => {
        try {
          await resendOwnPortalUserValidationEmail();

          notification.show(
            t('portal.email_validation.send.success'),
            'success',
            DEFAULT_NOTIFICATION_AUTO_HIDE_MS,
          );
        } catch (e) {
          Sentry.reportError('Failed to resend the email validation link', {
            error: e,
          });
          dialog.show((props) => (
            <ErrorDialog {...props} text={translateError(t, e)} />
          ));
        }
      };

      const handle = dialog.show((props) => (
        <ConfirmDialog
          {...props}
          onOk={onOk}
          header={t('portal.email_validation.dialog.title')}
          text={t('portal.email_validation.dialog.text')}
          cancelText={t('portal.email_validation.dialog.cancel')}
          okText={t('portal.email_validation.dialog.ok')}
        />
      ));

      return () => handle.close();
    }
  }, [loginStatus, user]);

  if (loginStatus === 'AUTHENTICATED' && !user?.email) {
    // Users are required to update their email if not set
    return (
      <Container>
        <Layout>
          <EmailRequestScreen />
        </Layout>
      </Container>
    );
  }

  if (loginStatus === 'AUTHENTICATED' && user?.mustChangePassword) {
    // Users are required to update their password if not set
    return (
      <Container>
        <Layout>
          <ChangePassword />
        </Layout>
      </Container>
    );
  }

  return (
    <Container>
      <ErrorBoundary fallback={ErrorScreen}>
        <BrowserSupportMessage />
        <ScheduledMaintenanceMessage />
        <Layout>
          <Suspense fallback={null}>
            <Switch>
              <SentryRoute exact path={routes.loginCallback}>
                <LoginCallback redirect={routes.login} />
              </SentryRoute>
              <SentryRoute exact path={routes.loginSsoCallback}>
                <SsoCallback redirect={routes.login} />
              </SentryRoute>
              <UnauthenticatedRoute
                exact
                path={routes.validateEmail}
                component={EmailValidationScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.passwordReset}
                component={ChangePasswordScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.login}
                component={LoginScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.loginSso}
                component={LoginSsoScreen}
              />
              <UnauthenticatedRoute
                exact
                path={routes.sessionExpiredSso}
                component={SessionExpiredScreen}
              />
              <AuthenticatedRoute
                exact
                path={routes.home}
                render={() => {
                  switch (company.defaultStartTab) {
                    case PortalTab.TELESIGN:
                      return <Redirect to={routes.telesign} />;
                    case PortalTab.SAFEMAIL:
                      return <Redirect to={routes.safemail} />;
                    case PortalTab.ADMIN:
                      return <Redirect to={routes.admin} />;
                    default:
                      return <Redirect to={routes.teleid} />;
                  }
                }}
              />
              <AuthenticatedRoute
                path={routes.teleid}
                component={TeleIdScreen}
              />
              <AuthenticatedRoute
                path={routes.telesign}
                component={TeleSignScreen}
              />
              <AuthenticatedRoute
                path={routes.safemail}
                component={SafeMailScreen}
              />
              <AuthenticatedRoute path={routes.admin} component={AdminScreen} />
              <AuthenticatedRoute
                exact
                path={routes.profile}
                component={ProfileScreen}
              />
              <SentryRoute
                render={() => {
                  if (loginStatus === 'LOGGED_OUT') {
                    return <Redirect to={routes.login} />;
                  }

                  return <NotFoundScreen />;
                }}
              />
            </Switch>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Container>
  );
};

export default PortalApp;
